import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
export const constantRouterMap = [
  {
    path: '/',
    meta: { title: '管理系统', noCache: true },
    component: (resolve) => require(['@/views/managerSystem'], resolve),
    hidden: true
  }
]

export default new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})


